import { FunctionalComponent, h } from 'preact';
import { DateTime } from 'luxon';

import style from './style.css';

const App: FunctionalComponent = () => {
    const formatAge = (): string => {
        const duration = DateTime.now().diff(DateTime.local(2021, 8, 18), ['days', 'months', 'years'])

        if (duration.years > 0) {
            return `Lucy is ${duration.years} years, ${duration.months} months and ${Math.floor(duration.days)} days old`;
        }

        return `Lucy is${duration.years > 0 ? `${duration.years} years, ` : ''} ${duration.months} months and ${Math.floor(duration.days)} days old`;
    }

    return (
        <div id="preact_root" class={style.home}>
            <div class={style.card}>
                <h3 class={style.intro}>The website of</h3>
                <h1>Lucy Jarman</h1>
                <p>{formatAge()}</p>
                <p>Made with 💜 by <a href="https://samjarman.co.nz">Uncle Sam</a></p>
            </div>
        </div>
    );
};

export default App;
